export const beautyPhotographersStatic = {
  crumbsNav: [
    { id: 1, title: 'Home', link: '/' },
    { id: 2, title: 'Beauty Photographers', link: '/beauty-photographers/' },
  ],
  tickers: [
    'Beauty',
    'Photographers',
    'Portfolio',
    'Beauty',
    'Photographers',
    'Portfolio',
  ],
};
