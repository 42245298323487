import React from 'react';
import Layout from '../components/Layout/layout';
import { graphql } from 'gatsby';

import Seo from '../components/seo';

import { MainScreenFirst, CrumbsNav } from '../components/Pages/About';

// import { beautyPhotographersData } from '../db/beautyPhotographersData';
import { contactsData } from '../db/contactsData';
import { beautyPhotographersStatic } from '../db/beautyPhotographersStatic';
import BeautyPhotographers from '../components/Pages/BeautyPhotographers/BeautyPhotographers';

const BeautyPhotographersPage = ({data}) => {

  return (
    <Layout>
      <Seo 
        title={data.strapiBeautyPhotographers.seo_title}  
        description={data.strapiBeautyPhotographers.seo_description}
      />
      <MainScreenFirst
        title={data.strapiBeautyPhotographers.main.title}
        socials={contactsData.socials}
        showreel={data.strapiBeautyPhotographers.main.showreel}
        video={data.strapiBeautyPhotographers.main.video_cover}
        poster={data.strapiBeautyPhotographers.main.poster}
      />

      <BeautyPhotographers
        items={data.strapiBeautyPhotographers.Photographers}
        tickers={beautyPhotographersStatic.tickers}
      />

      <CrumbsNav dataCrumbs={beautyPhotographersStatic.crumbsNav} />
    </Layout>
  );
};

export default BeautyPhotographersPage;

export const query = graphql`
 query {
  strapiBeautyPhotographers {
      seo_title
      seo_description
      main {
        title
        showreel
        video_cover {
          url
        }
        poster {
          url
        }
      }
      Photographers {
        title
        link
        cover {
          cover_image {
            localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 1400
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
          }
        }
      }
`