import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import PropTypes from 'prop-types';

import Ticker from '../../UiComponents/Ticker/Ticker';
import Arrow from '../../../svg/arrow-top-right-black.svg';

const BeautyPhotographers = ({ items, tickers }) => {
  
  return (
    <div className="photographers-portfolio" id="second-section" data-scroll-section>
      <Ticker tickers={tickers} />
      <div className="content-wrapper">
        <div className="photographers-portfolio__items">
          {items.map((item, index) => {
            
            return (
              <section className="photographers-portfolio__item" key={index}>
                <div className="portfolio__item-photos">
                  {item.cover.map((photo, index) => {
                    const image = getImage(photo.cover_image.localFile)
                    return (
                      <div
                        className="portfolio__item-photo__wrapper"
                        key={index}
                      >
                        <div
                          className="portfolio__item-photo__wrapper-overflow"
                          data-scroll=""
                          data-scroll-speed={
                            index === 0 ? '-0.7' : index === 1 ? '0' : '0.7'
                          }
                        >
                          <GatsbyImage
                            className="portfolio__item-photo"
                            image={image}
                            alt={item.title}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="portfolio-item__info">
                  <div className="portfolio-item__info-name">
                    <span className="portfolio-item__info-marker">
                      Photographer:
                    </span>
                    <h2 className="portfolio-item__info-title">{item.title}</h2>
                  </div>
                  <Link to={item.link} className="portfolio-item__link">
                    <span className="portfolio-item__link-title">
                      View more
                    </span>
                    <span className="portfolio-item__link-icon">
                      <Arrow />
                    </span>
                  </Link>
                </div>
              </section>
            );
          })}
        </div>
      </div>
    </div>
  );
};

BeautyPhotographers.propTypes = {
  items: PropTypes.array,
  tickers: PropTypes.array,
};

export default BeautyPhotographers;
